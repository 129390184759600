/* 基础布局样式 */
.profile-container {
    padding: 24px;
    max-width: 800px;
    margin: 0 auto;
}

.edit-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.edit-form {
    padding: 24px 0;
}

.avatar-upload {
    text-align: center;
    margin-bottom: 24px;
}

.avatar-uploader .ant-upload {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    overflow: hidden;
    margin: 0 auto;
}

.avatar-uploader .ant-upload img {
    width: 128px;
    height: 128px;
    object-fit: cover;
}

/* 生活照相关样式 */
.life-photo-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.life-photo-thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    transition: transform 0.3s ease;
}

.life-photo-wrapper:hover .life-photo-thumbnail {
    transform: scale(1.05);
}

.life-photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.life-photo-wrapper:hover .life-photo-overlay {
    opacity: 1;
}

.photo-action-btn {
    color: #fff !important;
    border: none !important;
    padding: 4px 8px !important;
    height: auto !important;
}

.photo-action-btn:hover {
    color: #40a9ff !important;
    background: rgba(255, 255, 255, 0.1) !important;
}

/* 上传按钮样式 */
.upload-photo-button {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    border: 2px dashed #e8e8e8;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-photo-button:hover {
    border-color: #1890ff;
    background-color: #f0f7ff;
}

.upload-photo-inner {
    text-align: center;
}

.upload-icon {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 8px;
}

.upload-text {
    color: #666;
    font-size: 14px;
    display: block;
}

/* 图片预览模态框样式 */
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.preview-modal img {
    max-width: 95vw;
    max-height: 95vh;
    object-fit: contain;
}

/* form actions */
.form-actions {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    gap: 16px;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
    .profile-container {
        padding: 12px;
    }

    .edit-card {
        border-radius: 0;
        box-shadow: none;
    }

    .edit-form {
        padding: 12px 0;
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    .ant-input-number, 
    .ant-select,
    .ant-picker {
        width: 100% !important;
    }

    .ant-btn {
        height: 44px;
        font-size: 16px;
    }

    .life-photo-overlay {
        opacity: 1;  /* 在移动端默认显示操作按钮 */
    }

    .upload-icon {
        font-size: 20px;
        margin-bottom: 4px;
    }

    .upload-text {
        font-size: 12px;
    }

    .photo-action-btn {
        padding: 2px 6px !important;
    }
}

/* 小屏幕手机适配 */
@media screen and (max-width: 375px) {
    .upload-icon {
        font-size: 18px;
    }

    .upload-text {
        font-size: 10px;
    }
}
/* 修改网格布局的间距 */
.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;  /* 增加间距到24px */
    margin-bottom: 24px;  /* 底部也加个边距 */
}

/* 小屏幕时调整间距 */
@media screen and (max-width: 768px) {
    .grid {
        gap: 16px;  /* 移动端稍微缩小一点间距 */
    }
}