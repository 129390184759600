.activity-page-container {
    padding: 24px;
    background: #f0f2f5;
    min-height: 100%;
}

.activity-card {
    background: #fff;
    border-radius: 2px;
}

.activity-list {
    margin-top: 16px;
}

.activity-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
}

@media screen and (max-width: 768px) {
    .activity-page-container {
        padding: 12px;
    }
    
    .activity-title {
        font-size: 14px;
    }
}