/* 基础布局样式 */
.custom-page-container {
    padding: 20px;
 }
 
 .filter-card {
    margin-bottom: 20px;
 }
 
 .filter-form {
    transition: all 0.3s ease;
 }
 
 .filter-form.collapsed {
    display: none;
 }
 
 /* 表单按钮样式 */
 .form-buttons {
    text-align: right;
    margin-top: 8px;
 }
 
 /* 列表项样式 */
 .list-item-title {
    word-break: break-all;
    white-space: normal;
 }
 
 .list-item-description {
    word-break: break-all;
    white-space: normal;
 }
 
 .custom-list .ant-list-item {
    transition: all 0.3s ease;
 }
 
 /* 弹窗样式 */
 .custom-modal .ant-modal-content {
    padding: 24px;
 }
 
 /* 区间范围输入框容器样式 */
 .range-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
 }
 
 .range-separator {
    flex: 0 0 auto;
    color: #999;
 }
 
 /* 移动端适配样式 */
 @media screen and (max-width: 768px) {
    /* 页面容器 */
    .custom-page-container {
        padding: 10px;
    }
 
    /* 筛选卡片 */
    .filter-card .ant-card-head {
        min-height: 40px;
        padding: 0 12px;
    }
 
    .filter-card .ant-card-head-title {
        padding: 12px 0;
        font-size: 14px;
    }
 
    /* 表单按钮 */
    .form-buttons {
        text-align: center;
        padding: 8px;
    }
 
    .form-buttons .ant-space {
        width: 100%;
    }
 
    /* 操作按钮样式 */
    .action-button {
        height: 44px !important;
        font-size: 16px !important;
        width: 100% !important;
        margin-bottom: 8px;
        border-radius: 4px !important;
    }
 
    .action-button:active {
        opacity: 0.8;
    }
 
    /* 区间范围输入框 */
    .range-input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
 
    .range-input-container .ant-input-number {
        width: 45% !important;
    }
 
    .ant-input-number-handler-wrap {
        display: none;
    }
 
    .ant-input-number-input {
        padding: 4px 8px !important;
        text-align: center;
    }
 
    /* 表单项样式 */
    .ant-form-item {
        margin-bottom: 8px;
    }
 
    .ant-form-item-label {
        padding-bottom: 4px;
    }
 
    /* 输入框统一样式 */
    .ant-input-number,
    .ant-select {
        width: 100% !important;
        height: 36px !important;
    }
 
    /* Space 组件间距 */
    .ant-space {
        gap: 8px !important;
    }
 
    /* Space 垂直布局 */
    .ant-space-vertical {
        width: 100%;
    }
 
    /* 确保 Space 组件中的按钮全宽 */
    .ant-space-vertical .ant-space-item {
        width: 100%;
    }
 
    /* 列表样式 */
    .custom-list .ant-list-item {
        padding: 12px 8px;
    }
 
    .custom-list .ant-list-item-meta-title {
        font-size: 14px;
        margin-bottom: 4px;
    }
 
    .custom-list .ant-list-item-meta-description {
        font-size: 12px;
    }
 
    /* 分页样式 */
    .ant-pagination {
        text-align: center;
        float: none !important;
    }
 
    /* 弹窗样式 */
    .custom-modal .ant-modal-content {
        padding: 12px;
    }
 
    /* Select 多选模式样式 */
    .ant-select-multiple .ant-select-selection-item {
        font-size: 12px;
        padding: 0 4px;
    }
 
    /* 表单组件样式优化 */
    .ant-input-number-group-wrapper {
        width: 100%;
    }
 
    /* 重置和搜索按钮容器 */
    .form-action-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        padding: 0 16px;
    }
 
    /* Card 内容区域padding */
    .ant-card-body {
        padding: 12px !important;
    }
 
    /* 表单项之间的间距 */
    .ant-form-item + .ant-form-item {
        margin-top: 12px;
    }
 
    /* 输入框占位文字大小 */
    .ant-input::placeholder,
    .ant-input-number-input::placeholder {
        font-size: 14px;
    }
 
    /* Select 选择框样式 */
    .ant-select-selector {
        height: 36px !important;
    }
 
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
        line-height: 36px !important;
    }
 
    /* 标签文字大小 */
    .ant-form-item-label > label {
        font-size: 14px;
    }
 }