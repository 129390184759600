.activity-customer-page {
    padding: 16px 0;
}

.activity-info-card h3 {
    margin: 0 0 16px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
}

.stats-card .ant-statistic {
    margin-bottom: 16px;
}

.participants-list {
    background: #fff;
    padding: 16px;
    border-radius: 2px;
}

.participant-title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.participant-name {
    font-weight: 500;
}

.participant-gender {
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px;
}

.participant-gender.male {
    background: #e6f7ff;
    color: #1890ff;
}

.participant-gender.female {
    background: #fff0f6;
    color: #eb2f96;
}

.participant-info {
    color: rgba(0, 0, 0, 0.65);
}

.contact-info {
    margin-bottom: 4px;
}

.occupation-info {
    margin-bottom: 4px;
}

.remarks-info {
    color: #faad14;
}

@media screen and (max-width: 576px) {
    .activity-customer-page {
        padding: 8px 0;
    }

    .ant-card {
        border-radius: 0;
    }

    .participants-list {
        padding: 8px;
    }
}