body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }
 
 code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
 }
 
 .ant-layout-topaside {
  height: 100%;
 }
 
 .ant-layout-topaside .ant-layout-wrapper {
  padding: 0 50px;
 }
 
 .ant-layout-topaside .ant-layout-header {
  background: #404040;
  height: 64px;
 }
 
 .ant-layout-topaside .ant-layout-logo {
  width: 120px;
  height: 32px;
  background: #333;
  border-radius: 6px;
  margin: 16px 28px 16px 0;
  float: left;
 }
 
 .ant-layout-topaside .ant-layout-subheader {
  height: 48px;
  border-bottom: 1px solid #e9e9e9;
  background: #fff;
 }
 
 .ant-layout-topaside .ant-layout-breadcrumb {
  margin: 7px 0 -17px 24px;
 }
 
 .ant-layout-topaside .ant-layout-container {
  background: #fff;
  margin: 24px 0 0;
  position: relative;
  padding: 24px 0;
  overflow: hidden;
 }
 
 .ant-layout-topaside .ant-layout-sider {
  width: 224px;
  float: left;
  min-height: calc(100vh - 250px);
 }
 
 .ant-layout-topaside .ant-layout-content {
  border-left: 1px solid #e9e9e9;
  padding: 0 24px;
  overflow: auto;
  position: relative;
  left: -1px;
  min-height: calc(100vh - 250px);
 }
 
 .ant-layout-topaside .ant-layout-footer {
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 12px;
  color: #999;
 }
 
 /* 响应式样式 */
 @media screen and (max-width: 768px) {
  .ant-layout-topaside .ant-layout-wrapper {
    padding: 0 15px;
  }
 
  .ant-layout-topaside .ant-layout-container {
    margin: 12px 0 0;
    padding: 12px 0;
  }
 
  .ant-layout-topaside .ant-layout-sider {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: white;
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    min-height: 100vh;
  }
 
  .ant-layout-topaside .ant-layout-sider.collapsed {
    transform: translateX(-100%);
  }
 
  .ant-layout-topaside .ant-layout-sider:not(.collapsed) {
    transform: translateX(0);
  }
 
  .ant-layout-topaside .ant-layout-content {
    border-left: none;
    padding: 0 12px;
    width: calc(100% - 24px);
    left: 0;
    min-height: calc(100vh - 200px);
  }
 
  .mobile-menu-toggle {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    padding: 8px 12px;
    background: #1890ff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
 
  .ant-layout-topaside .ant-layout-breadcrumb {
    margin: 7px 0 -17px 40px;
  }
 
  .ant-layout-topaside .ant-layout-footer {
    height: 48px;
    line-height: 48px;
  }
 }
 
 /* 移动端遮罩 */
 .mobile-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 998;
 }
 
 /* 菜单展开时显示遮罩 */
 @media screen and (max-width: 768px) {
  .ant-layout-sider:not(.collapsed) + .mobile-mask {
    display: block;
  }
 }
 
 /* 移动端表格优化 */
 @media screen and (max-width: 768px) {
  .ant-table {
    font-size: 12px;
  }
  
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 8px 4px;
    white-space: nowrap;
  }
 
  /* 表格容器添加横向滚动 */
  .ant-table-wrapper {
    overflow-x: auto;
  }
 
  /* 表单项在移动端垂直排列 */
  .ant-form-item {
    flex-direction: column;
  }
 
  .ant-form-item-label {
    text-align: left;
  }
  
  /* 按钮在移动端占满宽度 */
  .ant-btn-block {
    width: 100%;
    margin-bottom: 8px;
  }
  
  /* 改进移动端的间距 */
  .ant-form,
  .ant-table-wrapper {
    margin-bottom: 16px;
  }
  
  /* 调整卡片内边距 */
  .ant-card-body {
    padding: 12px;
  }
 }