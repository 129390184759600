/* CustomerEditPage.css */
.edit-page-container {
    padding: 24px;
    max-width: 800px;
    margin: 0 auto;
}

.edit-card {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.edit-form {
    padding: 24px 0;
}

.avatar-upload {
    text-align: center;
    margin-bottom: 24px;
}

.avatar-uploader .ant-upload {
    width: 128px;
    height: 128px;
    border-radius: 64px;
    overflow: hidden;
    margin: 0 auto;
}

.avatar-uploader .ant-upload img {
    width: 128px;
    height: 128px;
    object-fit: cover;
}

/* 生活照相关样式 */
.aspect-square {
    aspect-ratio: 1/1;
}

.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

@media (min-width: 768px) {
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.gap-4 {
    gap: 1rem;
}

.group:hover .group-hover\:opacity-100 {
    opacity: 1;
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
    .edit-page-container {
        padding: 0;
    }

    .edit-card {
        margin-top: 8px;
    }

    .activity-info-card {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .activity-info-card .ant-card-body {
        padding: 12px;
    }

    .ant-form-item {
        margin-bottom: 16px;
    }

    .ant-input-number, 
    .ant-select,
    .ant-picker {
        width: 100% !important;
    }

    .ant-btn {
        height: 44px;
        font-size: 16px;
    }
}


/* CustomerEditPage.css 中添加 */
.upload-photo-button {
    width: 100%;
    aspect-ratio: 1;
    background-color: #fafafa;
    border: 2px dashed #e8e8e8;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.upload-photo-button:hover {
    border-color: #1890ff;
    background-color: #f0f7ff;
}

.upload-photo-inner {
    text-align: center;
    padding: 8px;
    transition: all 0.3s ease;
}

.upload-photo-button:hover .upload-photo-inner {
    transform: scale(1.05);
}

.upload-icon {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 8px;
    transition: all 0.3s ease;
}

.upload-photo-button:hover .upload-icon {
    color: #40a9ff;
}

.upload-text {
    color: #666;
    font-size: 14px;
    display: block;
    transition: all 0.3s ease;
}

.upload-photo-button:hover .upload-text {
    color: #1890ff;
}

/* 针对移动端的优化 */
@media screen and (max-width: 768px) {
    .upload-photo-button {
        border-width: 1px;
    }
    
    .upload-icon {
        font-size: 20px;
    }
    
    .upload-text {
        font-size: 12px;
    }
}

/* 生活照部分样式 */
.life-photos-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.life-photo-item {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
}

.life-photo-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.life-photo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.life-photo-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.life-photo-wrapper:hover .life-photo-overlay {
    opacity: 1;
}

.photo-action-btn {
    color: #fff !important;
    border: none !important;
    padding: 4px 8px !important;
    height: auto !important;
}

.photo-action-btn:hover {
    color: #40a9ff !important;
    background: rgba(255, 255, 255, 0.1) !important;
}

/* 上传按钮样式 */
.upload-photo-button {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    border: 2px dashed #e8e8e8;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-photo-button:hover {
    border-color: #1890ff;
    background-color: #f0f7ff;
}

.upload-photo-inner {
    text-align: center;
}

.upload-icon {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 8px;
}

.upload-text {
    color: #666;
    font-size: 14px;
    display: block;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
    .life-photos-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }

    .upload-icon {
        font-size: 20px;
        margin-bottom: 4px;
    }

    .upload-text {
        font-size: 12px;
    }

    .photo-action-btn {
        padding: 2px 6px !important;
    }

    .life-photo-overlay {
        /* 在移动端默认显示操作按钮 */
        opacity: 1;
    }
}

/* 小屏幕手机适配 */
@media screen and (max-width: 375px) {
    .life-photos-container {
        gap: 6px;
    }

    .upload-icon {
        font-size: 18px;
    }

    .upload-text {
        font-size: 10px;
    }
}

/* 图片预览弹窗样式 */
.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.preview-content {
    max-width: 90vw;
    max-height: 90vh;
}

.preview-content img {
    max-width: 100%;
    max-height: 90vh;
    object-fit: contain;
}

.activity-info-card {
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    margin: 8px;
}

.activity-info-content {
    line-height: 1.4;
}

/* 针对移动端的样式优化 */
@media screen and (max-width: 768px) {
    .edit-page-container {
        padding-top: 8px;
    }
    
    .activity-info-card {
        margin: 8px 12px;
    }
}