/* CustomerLogin.css */

/* 背景和容器样式 */
.login-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #1890ff 0%, #001529 100%);
    padding: 20px;
}

/* 卡片样式 */
.login-card {
    width: 400px;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
}

/* 标题样式 */
.login-title {
    text-align: center;
    margin: 0 0 20px;
    color: #1890ff;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 1px;
}

/* 标签页样式 */
.login-tabs .ant-tabs-nav {
    margin-bottom: 24px;
}

.login-tabs .ant-tabs-tab {
    padding: 12px 16px;
    font-size: 15px;
    transition: all 0.3s;
}

.login-tabs .ant-tabs-tab:hover {
    color: #1890ff;
}

.login-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 500;
}

.login-tabs .ant-tabs-ink-bar {
    background: #1890ff;
    height: 3px;
    border-radius: 3px;
}

/* 表单样式 */
.login-form .ant-form-item {
    margin-bottom: 24px;
}

.login-form .ant-input-affix-wrapper {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    transition: all 0.3s;
}

.login-form .ant-input-affix-wrapper:hover,
.login-form .ant-input-affix-wrapper-focused {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.login-form .ant-input {
    font-size: 15px;
}

.login-form .ant-input-prefix {
    color: #bfbfbf;
    font-size: 18px;
    margin-right: 8px;
}

/* 按钮样式 */
.login-form .ant-btn {
    height: 48px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: all 0.3s;
}

.login-form .ant-btn-primary {
    background: linear-gradient(45deg, #1890ff, #40a9ff);
    border: none;
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.3);
}

.login-form .ant-btn-primary:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(24, 144, 255, 0.4);
}

.login-form .ant-btn-primary:active {
    transform: translateY(0);
}

/* 验证码按钮样式 */
.login-form .ant-btn-default {
    border: 1px solid #1890ff;
    color: #1890ff;
    height: 48px;
}

.login-form .ant-btn-default:hover {
    color: #40a9ff;
    border-color: #40a9ff;
    background: rgba(24, 144, 255, 0.05);
}

.login-form .ant-btn-default:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
}

/* 输入框验证状态 */
.login-form .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ff4d4f;
}

.login-form .ant-form-item-explain-error {
    font-size: 13px;
    margin-top: 4px;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
    .login-container {
        padding: 16px;
        background: linear-gradient(135deg, #1890ff 0%, #001529 100%);
    }

    .login-card {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }

    .login-title {
        font-size: 24px;
        margin-bottom: 16px;
    }

    .login-tabs .ant-tabs-tab {
        padding: 8px 12px;
        font-size: 14px;
    }

    .login-form .ant-input-affix-wrapper {
        padding: 10px 12px;
    }

    .login-form .ant-form-item {
        margin-bottom: 20px;
    }

    /* 验证码输入框组 */
    .login-form .ant-row {
        margin: 0 -4px;
    }

    .login-form .ant-col {
        padding: 0 4px;
    }

    /* 适配iPhone底部安全区域 */
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        .login-container {
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
        }
    }
}

/* 暗色模式适配 */
@media (prefers-color-scheme: dark) {
    .login-card {
        background: rgba(0, 0, 0, 0.8);
        border-color: rgba(255, 255, 255, 0.08);
    }

    .login-title {
        color: #1890ff;
    }

    .login-form .ant-input-affix-wrapper {
        background: rgba(255, 255, 255, 0.04);
        border-color: rgba(255, 255, 255, 0.08);
    }

    .login-form .ant-input {
        background: transparent;
        color: rgba(255, 255, 255, 0.85);
    }

    .login-form .ant-input-prefix {
        color: rgba(255, 255, 255, 0.45);
    }

    .login-tabs .ant-tabs-tab {
        color: rgba(255, 255, 255, 0.45);
    }

    .login-tabs .ant-tabs-tab:hover {
        color: #1890ff;
    }

    .login-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1890ff;
    }
}

/* 动画效果 */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-card {
    animation: fadeIn 0.5s ease-out;
}